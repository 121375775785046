import React, { Component } from 'react';
import Link from 'gatsby-link'
import SbEditable from 'storyblok-react'
import logo from '../images/logo.svg'
import zipIcon from '../images/zip-icon.svg'
import BasketTotal from './BasketTotal'

class Header extends Component {
  constructor(props) {
    super(props)
    this.toggleNav = this.toggleNav.bind(this)
    this.state = {
      navOpen: false
    }
  }
  toggleNav() {
    this.setState(prevState => ({ navOpen: !prevState.navOpen }))
  }

  render() {
    return (
      <header className={`site-header${(this.props.invert) ? ' site-header-invert' : ''}`}>
        <nav className="site-header__inner" role="navigation">
          <button className={`burger-nav ${(this.state.navOpen) ? 'burger-nav--open' : ''}`} onClick={this.toggleNav}>
            <svg width="52px" height="52px" viewBox="0 0 52 52">
              <path className="zipstroke" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
              <path className="zipdashes" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
            </svg>
          </button>

          <Link className="logomark site-header__logo" to="/">
            <img alt="YKK" src={logo} />
          </Link>

          <div>
            <Link className="site-header__link testimonials-link" to="/teaser-film/">Teaser Film</Link>
            <Link className="site-header__link testimonials-link" to="/testimonials/">Testimonials</Link>
            
            <Link className="request-a-sample-btn" to="/request-a-sample-pack/">
              <span className="request-a-sample-btn__text-desktop">
                Request a sample pack
              </span>
              <span className="request-a-sample-btn__text-mobile">
                Request a<br/>sample pack
              </span>
              <div className="request-a-sample-btn__icon">
                <img alt="Zip icon" src={zipIcon} />
                <svg width="52px" height="52px" viewBox="0 0 52 52">
                  <path className="zipstroke" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                  <path className="zipdashes" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                </svg>
              </div>
              <BasketTotal />
            </Link>
          </div>

          <div className={`site-header__nav ${(this.state.navOpen) ? 'site-header__nav--open' : ''}`}>
          {this.props.settings &&
            this.props.settings.content.main_navi.map((navitem, index) => (
              <SbEditable content={navitem} key={navitem._uid}>
                <Link key={index} to={`/${navitem.link.cached_url.replace("en/", "").replace("home", "")}`} prefetch="true" className="site-header__nav-link">
                  {navitem.name}
                </Link>
              </SbEditable>
            ))}
          </div>
        </nav>
      </header>
    )
  }
}

export default Header;
