import React, { useState, useEffect } from 'react'

const BasketTotal = ({productID}) => {
  const [initialized, setInitialized] = useState(false);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [basketTotal, setBasketTotal] = useState(0);
  useEffect(() => {
    if (!windowLoaded) {
      if (typeof window !== 'undefined' && window) {
        setWindowLoaded(true)
      }
    }
  }, [windowLoaded]);
  useEffect(() => {
    if (!initialized && windowLoaded) {

      let productIds = JSON.parse( localStorage.getItem('productIds') || '0' )
      setBasketTotal(productIds.length);


      window.addEventListener("storage",(e) => {
        productIds = JSON.parse( localStorage.getItem('productIds') || '0' )
        setBasketTotal(productIds.length);
      });

      setInitialized(true);
    }
  }, [initialized, windowLoaded]);


  // let productIds = JSON.parse( localStorage.getItem('productIds') || '0' )
  // this.setState({
  //   basketTotal: productIds.length
  // })

  return (
    (initialized && basketTotal > 0) ? <i>{basketTotal}</i> : ''
  )
}


export default BasketTotal
